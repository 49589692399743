import { CLEAR_NOEUD_OUVERT, SET_NOEUD_OUVERT } from 'actions/noeudOuvertAction'

const defaultStateNoeud = {
  noeudOuvert: []
}

export default function noeudOuvertReducer(state = defaultStateNoeud, action) {
  switch (action.type) {
    case SET_NOEUD_OUVERT:
      return { ...state, noeudOuvert: action.payload }
    case CLEAR_NOEUD_OUVERT:
      return defaultStateNoeud
    default:
      return state
  }
}
