import React from 'react'
import { makeStyles } from '@mui/styles'
import { InseeTheme } from '../Provider/material-ui-theme'
import ListeAgent from './ListeAgent'
import RechercheAgent from './RechercheAgent'
import { useSelector } from 'react-redux'
import { Progress } from 'components/Utils/Progress'

//css bloc agent
const useStyles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.selected.cadre,
    color: theme.palette.selected.texte
  },
  tree: {
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '70vh',
    minHeight: '55vh'
  }
}))

export default function BlocAgent() {
  const classes = useStyles(InseeTheme)
  const agentsTotal = useSelector((store) => store.agentTotalReducer.agentsTotal)

  return agentsTotal.length > 0 ? (
    <div>
      <RechercheAgent />
      <div className={classes.tree}>
        <ListeAgent />
      </div>
    </div>
  ) : (
    <Progress texte="Chargement des agents en cours..." />
  )
}
