import { Autocomplete, TextField, Paper } from '@mui/material'
import { clearAgent, setAgentSelected } from 'actions/agentAction'
import { setNoeudOuvert } from 'actions/noeudOuvertAction'
import { setNoeudSelected } from 'actions/noeudSelectedAction'
import { affichageNomPrenom, getAgentByRecherche } from 'components/Utils/Fonctions'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import store from '../../index'
import { champSansAccent, getIdNoeudsAscendants } from '../Utils/Fonctions'
import { useParams, useLocation } from 'react-router-dom'

const styles = {
  '&& .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: 'hover.cadre'
  }
}

export default function RechercheAgent() {
  const agentsTotal = useSelector((store) => store.agentTotalReducer.agentsTotal)
  const noeuds = useSelector((store) => store.dimensionReducer.noeuds)
  const [inputValue, setInputValue] = useState(null)
  const location = useLocation()
  const { idep } = useParams()
  const { pathname } = location

  const setListeAgents = useCallback(
    (agent) => {
      let idNoeudSelected = null
      let noeud = []
      let noeudsAscendants = []
      if (agent?.affectation) {
        store.dispatch(setAgentSelected(agent))
        noeud = noeuds.filter((n) => n.valeurs.includes(agent.affectation.unite))
        if (noeud.length > 0) {
          idNoeudSelected = noeud[0].identifiantNoeudDimension
          noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud[0])
        }
      } else {
        store.dispatch(clearAgent())
      }
      store.dispatch(setNoeudOuvert(noeudsAscendants))
      store.dispatch(setNoeudSelected(idNoeudSelected))
    },
    [noeuds]
  )

  useEffect(() => {
    if (pathname === '/') {
      setInputValue(null)
    } else {
      const agent = agentsTotal.find((value) => value.identifiantsAgent.idep?.toUpperCase() === idep?.toUpperCase())
      if (agent) {
        setListeAgents(agent)
      }
    }
  }, [pathname, agentsTotal, idep, setListeAgents])

  // création d'une map avec le code des unités et le timbre que l'on récupère dans le libellé
  const mapCodeLibelle = {}
  noeuds.forEach((e) => {
    e.valeurs.forEach((v) => {
      const unite = e.libelle.split(' ~ ')[1]
      if (unite) {
        mapCodeLibelle[v] = ` (${unite})`
      }
    })
  })

  const searchAgentByName = (recherche) => {
    let recherche2 = null
    // pour éviter les agents qui ont un nom null
    let agentsPartiel = agentsTotal.filter((a) => a.etatCivil.nomUsage !== null)
    if (recherche) {
      recherche2 = champSansAccent(String(recherche))
    }
    const result = getAgentByRecherche(agentsPartiel, recherche2)
    if (result.length > 0) {
      setInputValue(result[0])
    } else {
      setInputValue(recherche)
    }
    return result
  }

  const miseEnFormeAgentName = (agent) => {
    if (mapCodeLibelle[agent.affectation.unite]) {
      return (
        affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel) +
        mapCodeLibelle[agent.affectation.unite]
      )
    } else {
      return affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel)
    }
  }

  const renderAgentName = useCallback(
    (agent) => {
      if (agent.affectation) {
        return miseEnFormeAgentName(agent)
      }
      if (inputValue && typeof inputValue === 'object') {
        return miseEnFormeAgentName(inputValue)
      }
      return inputValue
    },
    [inputValue]
  )

  const onSubmit = (e) => {
    setListeAgents(inputValue)
    e.preventDefault()
  }

  const label = "Recherche d'un agent par nom/prénom, tél, bureau, idep, balf"

  const getPaper = useCallback(({ children }) => {
    return <Paper sx={styles}>{children}</Paper>
  }, [])

  const filterOptions = useCallback(
    (option, value) => {
      return value?.inputValue ? searchAgentByName(value.inputValue) : ''
    },
    [inputValue]
  )

  const onChange = useCallback((e, value) => {
    setListeAgents(value)
  }, [])

  const renderInput = useCallback((params) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        margin="normal"
        color="secondary"
        inputProps={{
          ...params.inputProps,
          'aria-label': `${label} Les résultats sont affichés à la volée`
        }}
      />
    )
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <Autocomplete
        clearText={'Effacer la saisie'}
        clearOnEscape={true}
        options={agentsTotal}
        getOptionLabel={renderAgentName}
        PaperComponent={getPaper}
        filterOptions={filterOptions}
        freeSolo
        aria-label={label}
        onChange={onChange}
        renderInput={renderInput}
      />
    </form>
  )
}
