import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { TreeView, TreeItem } from '@mui/x-tree-view'
import { setNoeudOuvert } from 'actions/noeudOuvertAction'
import store from '../../index'
import { setNoeudSelected } from 'actions/noeudSelectedAction'
import { Typography } from '@mui/material'
import { clearAgent } from 'actions/agentAction'

const styles = {
  '.MuiTreeItem-content.Mui-selected': {
    backgroundColor: 'selected.cadre'
  },
  '.MuiTreeItem-content.Mui-selected:hover, .MuiTreeItem-content.Mui-selected.Mui-focused': {
    backgroundColor: 'selectedHover.cadre'
  },
  '.MuiTreeItem-content:hover, .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'hover.cadre'
  },
  '.MuiTreeItem-content:hover': {
    userSelect: 'text'
  }
}

const ArbreUnite = () => {
  const agents = useSelector((store) => store.agentReducer.agents)
  const noeuds = useSelector((store) => store.dimensionReducer.noeuds)
  const noeudOuvert = useSelector((store) => store.noeudOuvertReducer.noeudOuvert)
  const noeudSelected = useSelector((store) => store.noeudSelectedReducer.noeudSelected)
  const [noeudsSommet, setNoeudsSommet] = React.useState([])
  const [noeudsAvecEnfants, setNoeudsAvecEnfants] = React.useState([])

  useEffect(() => {
    // création d'une liste de tous les noeuds avec pour chacun ses enfants
    let newList = {}
    noeuds.forEach(
      (n) =>
        (newList = {
          ...newList,
          [n.identifiantNoeudDimension]: noeuds.filter(
            (n2) => n2.identifiantNoeudParent === n.identifiantNoeudDimension
          )
        })
    )
    setNoeudsAvecEnfants(newList)
    // récupération dans les données des noeuds sommets, c-a-d ceux pour qui identifiantNoeudParent = null
    setNoeudsSommet(noeuds.filter((e) => e.identifiantNoeudParent === null))
  }, [noeuds])

  // lors d'un click sur un noeud, permet de déplier/replier ce noeud
  const selectionNoeud = (e, idNoeud) => {
    // suppression des agents seulement si on change de noeud
    if (noeudSelected !== idNoeud) {
      store.dispatch(clearAgent())
    }
    store.dispatch(setNoeudSelected(idNoeud))
    if (noeudOuvert.includes(idNoeud)) {
      const nouveauxNoeuds = noeudOuvert.filter((n) => n !== idNoeud)
      store.dispatch(setNoeudOuvert(nouveauxNoeuds))
    } else {
      const nouveauxNoeuds = [...noeudOuvert]
      nouveauxNoeuds.push(idNoeud)
      store.dispatch(setNoeudOuvert(nouveauxNoeuds))
    }
  }

  const vocaliserUnite = (noeud, isNoeudSelected) => {
    if (isNoeudSelected && agents.length !== 0) {
      return 'Il y a ' + agents.length + " agents dans l'unité " + noeud.libelle.split('~')[0]
    }
    if (isNoeudSelected && agents.length === 0) {
      return "Aucun agent dans l'unité " + noeud.libelle.split('~')[0]
    }
  }

  // ajoute à l'arborescence une modalité et toute sa descendance
  const affichageDescendants = (noeud) => {
    const listeFils = noeudsAvecEnfants[noeud.identifiantNoeudDimension] // récupération des noeuds enfants du noeud en paramètre
    const isNoeudSeleced = noeud.identifiantNoeudDimension === noeudSelected
    return (
      // création d'un TreeItem pour le noeud et de manière récursive pour ses enfants
      <TreeItem
        contextMenu="true"
        sx={styles}
        key={noeud.identifiantNoeudDimension}
        nodeId={noeud.identifiantNoeudDimension}
        aria-label={vocaliserUnite(noeud, isNoeudSeleced)}
        label={
          <Typography
            component="span"
            noWrap={!isNoeudSeleced}
            style={isNoeudSeleced ? { fontWeight: 'bold' } : {}}
          >
            {noeud.libelle.split('~')[0]}
          </Typography>
        }
        title={noeud.libelle}
      >
        {listeFils.map((fils) => affichageDescendants(fils))}
      </TreeItem>
    )
  }

  const onNodeSelect = useCallback(
    (e, id) => {
      selectionNoeud(e, id)
    },
    [noeudSelected, noeudOuvert]
  )

  return (
    <TreeView
      sx={{ userSelect: 'none' }}
      aria-label="navigation dans l'arbre des unités"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={noeudOuvert}
      onNodeSelect={onNodeSelect}
      selected={noeudSelected}
    >
      {noeudsSommet.map((n) => affichageDescendants(n))}
    </TreeView>
  )
}

export default ArbreUnite
