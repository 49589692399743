import { compareAgentsByName } from 'components/Utils/Fonctions'
import { GET_AGENT_TOTAL_SUCCESS, GET_AGENT_TOTAL_FAILURE, CLEAR_AGENT_TOTAL } from '../actions/agentTotalAction'

const defaultStateAgentTotal = {
  agentsTotal: [],
  erreur: ''
}

export default function agentTotalReducer(state = defaultStateAgentTotal, action) {
  switch (action.type) {
    case GET_AGENT_TOTAL_SUCCESS:
      const newAgents = action.payload.content
      newAgents.sort(compareAgentsByName)
      return { ...state, agentsTotal: newAgents, erreur: '' }
    case GET_AGENT_TOTAL_FAILURE:
      return { ...state, agentsTotal: [], erreur: action.error }
    case CLEAR_AGENT_TOTAL:
      return defaultStateAgentTotal
    default:
      return state
  }
}
