import React from 'react'
import { Link as LinkMUI, Typography } from '@mui/material'

export default function FAQ() {
  document.title = "FAQ - Contact | Annuaire de l'Insee"
  return (
    <>
      <br />
      <Typography
        variant="h4"
        component="h2"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        FAQ / Contact
      </Typography>

      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Comment mettre à jour ma photo ?
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        La photo présentée dans l’annuaire provient de l’intranet Symphonie.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Pour la modifier, suivez ce{' '}
        <LinkMUI
          href="https://intranet.insee.fr/jcms/8095839_Media/tutoriel-video-comment-mettre-a-jour-sa-photo"
          style={{ color: 'black', textDecoration: 'underline' }}
        >
          tutoriel vidéo
        </LinkMUI>
        .
      </Typography>

      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        D’où proviennent les informations de l’annuaire des personnes et des services ?
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Les informations proviennent majoritairement de SIRHIUS et sont fournies par APIRH avec un retard d’une journée.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        C’est le cas des caractéristiques de l’agent (affectation, grade, fonction…) ainsi que des structures
        organisationnelles (timbre, adresse…).
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        L’IDEP et le mail proviennent de l’annuaire général sous la responsabilité de la DSI.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        La photo provient de l’intranet Symphonie, toute modification de cette photo est répercutée dans l’annuaire dans
        l’heure.
      </Typography>

      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Comment mettre à jour mes informations personnelles ?
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Les informations concernant le téléphone, le portable professionnel et le bureau sont à modifier dans le portail
        agent de SIRHIUS, rubrique « Modifier mes données ».
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Si vous n’avez pas accès à Sirhius ou si vous êtes gérés par une autre Direction du ministère,{' '}
        <LinkMUI
          href="https://intranet.insee.fr/upload/docs/application/pdf/2023-01/plaquette_csrh_-_agents_eloignes_du_service_10012023_contact.pdf"
          style={{ color: 'black', textDecoration: 'underline' }}
        >
          contactez le CSRH
        </LinkMUI>
        .
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        La fonction et la qualité de responsable sont à faire modifier par la fonction RH locale.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Toute modification apparait sur l’annuaire le jour suivant.
      </Typography>

      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Comment mettre à jour les informations sur les structures ?
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Il faut se rapprocher de sa fonction RH locale qui pourra faire une demande à la DVD-SG selon le circuit de
        gestion habituel.
      </Typography>

      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        {'Pour toute autre question ou remarque, '}
        <LinkMUI href="mailto:dg75-sirh@insee.fr?subject=[TROMBI]">
          <Typography
            variant="h6"
            component="span"
            style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bold' }}
          >
            cliquez ici
          </Typography>
        </LinkMUI>
      </Typography>
    </>
  )
}
