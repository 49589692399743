import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { champSansAccent } from '../Utils/Fonctions'
import { setNoeudOuvert } from 'actions/noeudOuvertAction'
import { Autocomplete, TextField, Paper } from '@mui/material'
import store from '../../index'
import { setNoeudSelected } from 'actions/noeudSelectedAction'
import { clearAgent } from 'actions/agentAction'
import { getIdNoeudsAscendants, compareUnitesByLibelle } from 'components/Utils/Fonctions'

const styles = {
  '&& .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: 'hover.cadre'
  }
}

export default function RechercheUnite() {
  const noeuds = useSelector((store) => store.dimensionReducer.noeuds)
  const [listeResultats, setListeResultats] = React.useState([])
  const [inputValue, setInputValue] = useState(null)

  const searchUniteByLibelle = (recherche) => {
    let recherche2 = null
    if (recherche) {
      recherche2 = champSansAccent(String(recherche))
    }
    const resultats = noeuds.filter((n) => champSansAccent(n.libelle).includes(recherche2))
    resultats.sort(compareUnitesByLibelle)
    if (resultats.length > 0) {
      setInputValue(resultats[0])
    } else {
      setInputValue(recherche)
    }
    return resultats
  }

  const getListeIdNoeudAscendants = (noeud) => {
    let noeudsAscendants = []
    let idNoeudSelected = null
    if (noeud) {
      idNoeudSelected = noeud.identifiantNoeudDimension
      noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud)
    } else {
      // s'il n'y a pas de noeud, suppression des agents dans le store
      store.dispatch(clearAgent())
    }
    if (typeof noeud !== 'object') {
      store.dispatch(clearAgent())
    }
    store.dispatch(setNoeudOuvert(noeudsAscendants))
    store.dispatch(setNoeudSelected(idNoeudSelected))
  }

  const label = "Recherche d'une unité par libellé ou par timbre"

  const miseEnformeUniteName = useCallback(
    (unite) => {
      if (unite.libelle) {
        return unite.libelle
      }
      if (inputValue && typeof inputValue === 'object') {
        return inputValue.libelle
      }
      return inputValue
    },
    [inputValue]
  )

  const onSubmit = (event) => {
    getListeIdNoeudAscendants(inputValue)
    event.preventDefault()
  }

  const getPaper = useCallback(({ children }) => {
    return <Paper sx={styles}>{children}</Paper>
  }, [])

  const onChange = useCallback((e, value) => {
    getListeIdNoeudAscendants(value)
  }, [])

  const onTextFieldChange = useCallback((e) => {
    setListeResultats(searchUniteByLibelle(e.target.value))
  }, [])

  const renderInput = useCallback((params) => {
    return (
      <TextField
        {...params}
        onChange={onTextFieldChange}
        variant="outlined"
        label={label}
        margin="normal"
        color="secondary"
        inputProps={{
          ...params.inputProps,
          'aria-label': `${label} Les résultats sont affichés à la volée`
        }}
      />
    )
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <Autocomplete
        clearText={'Effacer la saisie'}
        clearOnEscape={true}
        options={listeResultats}
        getOptionLabel={miseEnformeUniteName}
        PaperComponent={getPaper}
        freeSolo
        aria-label={label}
        onChange={onChange}
        renderInput={renderInput}
      />
    </form>
  )
}
