/* eslint-disable import/no-cycle */
import API from '../api/api'

export const ERREUR_RESEAU = 'Une erreur est survenue'
export const GET_DIMENSION_SUCCESS = 'GET_DIMENSION_SUCCESS'
export const GET_DIMENSION_FAILURE = 'GET_DIMENSION_FAILURE'
export const CLEAR_DIMENSION = 'CLEAR_DIMENSION'

export function getDimension() {
  let requete = 'noeudsDimension?identifiantDimension=91&nombre=5000&'
  return (dispatch) => {
    API()
      .then((api) => api.get(requete))
      .then((result) => {
        dispatch({
          type: GET_DIMENSION_SUCCESS,
          payload: result.data
        })
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_DIMENSION_FAILURE,
            error: { message: ERREUR_RESEAU }
          })
        } else {
          dispatch({
            type: GET_DIMENSION_FAILURE,
            error: error.response.data
          })
        }
      })
  }
}
