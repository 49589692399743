import axios from 'axios'
import { refreshToken } from '../keycloak/keycloak'
import getConfiguration from '../configuration'
import store from '../index'

export default async function API(contentType = 'application/json;charset=utf-8') {
  const nomServeur = await getConfiguration('BACK_END_PATH')
  const myAxios = axios.create({
    baseURL: `${nomServeur}`
  })
  myAxios.interceptors.request.use((config) => {
    const kc = store.getState().tokenReducer.kc
    return refreshToken()
      .then(() => {
        config.headers.Authorization = `Bearer ${kc.token}`
        config.headers['Content-Type'] = `${contentType}`
        config.headers.Accept = `${contentType}`
        return Promise.resolve(config)
      })
      .catch(() => {
        kc.login()
      })
  })
  return myAxios
}
