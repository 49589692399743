import { compareAgentsByName } from 'components/Utils/Fonctions'
import {
  CLEAR_AGENT,
  GET_AGENT_FAILURE,
  GET_AGENT_SUCCESS,
  GET_PHOTO_AGENT_FAILURE,
  GET_PHOTO_AGENT_SUCCESS,
  GET_PHOTO_CONNECTED_AGENT_FAILURE,
  GET_PHOTO_CONNECTED_AGENT_SUCCESS,
  GET_UNITE_AGENT_SELECTED_FAILURE,
  GET_UNITE_AGENT_SELECTED_SUCCESS,
  SET_AGENT_SELECTED
} from '../actions/agentAction'

const defaultStateAgent = {
  agents: [],
  agentSelected: {},
  uniteAgentSelected: {},
  photoAgent: new Map(),
  photoConnectedAgent: '',
  erreur: ''
}

const sortAgents = (payload) => {
  return payload.sort(compareAgentsByName)
}

export default function agentReducer(state = defaultStateAgent, action) {
  switch (action.type) {
    case GET_AGENT_SUCCESS:
      return { ...state, agents: sortAgents(action.payload), erreur: '' }
    case GET_AGENT_FAILURE:
      return { ...state, agents: [], erreur: action.error }
    case SET_AGENT_SELECTED:
      return { ...state, agentSelected: action.payload }
    case GET_UNITE_AGENT_SELECTED_SUCCESS:
      return { ...state, uniteAgentSelected: action.payload, erreur: '' }
    case GET_UNITE_AGENT_SELECTED_FAILURE:
      return { ...state, uniteAgentSelected: {}, erreur: action.error }
    case GET_PHOTO_AGENT_SUCCESS:
      return { ...state, photoAgent: { ...state.photoAgent, [action.payload.matricule]: action.payload.value } }
    case GET_PHOTO_AGENT_FAILURE:
      return { ...state, erreur: action.error }
    case GET_PHOTO_CONNECTED_AGENT_SUCCESS:
      return { ...state, photoConnectedAgent: action.payload }
    case GET_PHOTO_CONNECTED_AGENT_FAILURE:
      return { ...state, erreur: action.error }
    case CLEAR_AGENT:
      return {
        agents: [],
        agentSelected: {},
        uniteAgentSelected: {},
        photoAgent: new Map(),
        photoConnectedAgent: state.photoConnectedAgent, // Keep the value for the connected agent photo
        erreur: ''
      }
    default:
      return state
  }
}
