export const SET_TOKEN = 'SET_TOKEN'
export const SET_KEYCLOAK = 'SET_KEYCLOAK'

export function setToken(token) {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN,
      payload: token
    })
  }
}

export function setKeycloak(kc) {
  return (dispatch) => {
    dispatch({
      type: SET_KEYCLOAK,
      payload: kc
    })
  }
}
