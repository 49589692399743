import { GET_HEALTHCHECK } from 'actions/healthcheckAction'

const defaultStateHealthcheck = {
  healthcheck: null
}

export default function healthcheckReducer(state = defaultStateHealthcheck, action) {
  if (action.type === GET_HEALTHCHECK) {
    return { ...state, healthcheck: action.payload }
  }
  return state
}
