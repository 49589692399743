import { CLEAR_NOEUD_SELECTED, SET_NOEUD_SELECTED } from 'actions/noeudSelectedAction'

const defaultStateNoeud = {
  noeudSelected: null
}

export default function noeudSelectedReducer(state = defaultStateNoeud, action) {
  switch (action.type) {
    case SET_NOEUD_SELECTED:
      return { ...state, noeudSelected: action.payload }
    case CLEAR_NOEUD_SELECTED:
      return defaultStateNoeud
    default:
      return state
  }
}
