import React from 'react'
import { Typography } from '@mui/material'

export default function Maintenance() {
  document.title = "Maintenance | Annuaire de l'Insee"
  return (
    <>
      <Typography
        variant="h3"
        component="h2"
        color="inherit"
      >
        Maintenance
      </Typography>
      <Typography
        variant="h4"
        component="p"
        color="inherit"
      >
        L'application n'est pas disponible pour le moment.
      </Typography>
      <Typography
        variant="h4"
        component="p"
        color="inherit"
      >
        Veuillez nous excuser pour la gêne occasionnée.
      </Typography>
    </>
  )
}
