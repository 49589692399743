import API from 'api/api'

export const GET_HEALTHCHECK = 'GET_HEALTHCHECK'

export function getHealthcheck() {
  let requete = 'healthcheck'
  return (dispatch) => {
    API()
      .then((api) => api.get(requete))
      .then((result) => {
        dispatch({
          type: GET_HEALTHCHECK,
          payload: result.status === 200
        })
      })
      .catch(() => {
        dispatch({
          type: GET_HEALTHCHECK,
          payload: false
        })
      })
  }
}
