import API from '../api/api'
import { SET_LOADING } from './loadingAction'

export const ERREUR_RESEAU = 'Une erreur est survenue'
export const GET_AGENT_TOTAL_SUCCESS = 'GET_AGENT_TOTAL_SUCCESS'
export const GET_AGENT_TOTAL_FAILURE = 'GET_AGENT_TOTAL_FAILURE'
export const CLEAR_AGENT_TOTAL = 'CLEAR_AGENT_TOTAL'

export function getAgentsTotal() {
  let requete = 'agents?Profil=Trombi&populations.etat=actuel&nombre=10000&populations.formateur=false'
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true
    })
    API()
      .then((api) => api.get(requete))
      .then((result) => {
        dispatch({
          type: GET_AGENT_TOTAL_SUCCESS,
          payload: result.data
        })
        dispatch({
          type: SET_LOADING,
          payload: false
        })
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_AGENT_TOTAL_FAILURE,
            error: { message: ERREUR_RESEAU }
          })
        } else {
          dispatch({
            type: GET_AGENT_TOTAL_FAILURE,
            error: error.response.data
          })
        }
        dispatch({
          type: SET_LOADING,
          payload: false
        })
      })
  }
}
