import Keycloak from 'keycloak-js'
import store from '../index'

const kcConfig = `${window.location.origin}/keycloak.json`
export const kc = new Keycloak(kcConfig)

export const refreshToken = (minValidity = 5) => {
  return new Promise((resolve, reject) => {
    store
      .getState()
      .tokenReducer.kc.updateToken(minValidity)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}
