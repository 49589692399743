import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from 'components/Root'
import rootReducer from './reducers/index'
import { applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { thunk } from 'redux-thunk'
import kc from './keycloak'
import getConfiguration from './configuration'
import { setKeycloak, setToken } from 'actions/tokenAction'
import { configureStore } from '@reduxjs/toolkit'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootElement = document.getElementById('root')
const store = configureStore({
  reducer: rootReducer,
  thunk: composeEnhancers(applyMiddleware(thunk)),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
})

export default store

getConfiguration('AUTHENTIFICATION').then(() => {
  kc.init({ onLoad: 'login-required' }).then((authenticated) => {
    if (authenticated) {
      store.dispatch(setKeycloak(kc))
      store.dispatch(setToken(kc.tokenParsed))
    }
  })
})

createRoot(rootElement).render(
  <Provider store={store}>
    <Root />
  </Provider>
)
