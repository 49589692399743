import React, { useEffect } from 'react'
import { Avatar } from '@mui/material'
import { getInitiales } from '../Utils/Fonctions'
import { getAgentPhoto } from 'actions/agentAction'
import { useDispatch, useSelector } from 'react-redux'

const MyAvatar = ({ agent, styles }) => {
  const initiales = getInitiales(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel)
  const photoAgent = useSelector((store) => store.agentReducer.photoAgent)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAgentPhoto(agent.identifiantsAgent.matricule))
  }, [agent.identifiantsAgent.matricule, dispatch])

  return (
    <Avatar
      aria-hidden="true"
      sx={styles}
      alt={''}
      src={'data:image/jpeg;base64,' + photoAgent[`${agent.identifiantsAgent.matricule}`]}
    >
      {' '}
      {initiales}
    </Avatar>
  )
}

export default MyAvatar
