import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import Accessibilite from './Link/Accessibilite'
import MentionsLegales from './Link/MentionsLegales'
import Securite from './Link/Securite'
import { InseeTheme } from './Provider/material-ui-theme'
import { getAgentsTotal } from 'actions/agentTotalAction'
import { getDimension } from 'actions/dimensionAction'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Maintenance from './Link/Maintenance'
import { getHealthcheck } from 'actions/healthcheckAction'
import Faq from './Link/FAQ'
import { getConnectedAgentPhoto } from 'actions/agentAction'
import { Authentification } from './Utils/Authentification'

export default function Root() {
  const dispatch = useDispatch()
  const tokenReducer = useSelector((store) => store.tokenReducer)
  const healthcheck = useSelector((store) => store.healthcheckReducer.healthcheck)
  useEffect(() => {
    if (tokenReducer.idep) {
      dispatch(getHealthcheck())
      dispatch(getAgentsTotal())
      dispatch(getDimension())
      dispatch(getConnectedAgentPhoto(tokenReducer.matricule))
    }
  }, [dispatch, tokenReducer.idep, tokenReducer.matricule])

  return (
    <ThemeProvider theme={InseeTheme}>
      {tokenReducer.idep ? (
        <div style={{ position: 'absolute', minHeight: '100%', width: '100%' }}>
          <BrowserRouter>
            <Header />
            <main
              style={{
                marginLeft: '0.5%',
                marginRight: '0.5%',
                minHeight: '75vh',
                paddingBottom: '60px'
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={healthcheck !== false ? <App /> : <Maintenance />}
                />
                <Route
                  path="/accessibilite"
                  element={<Accessibilite />}
                />
                <Route
                  path="/mentionsLegales"
                  element={<MentionsLegales />}
                />
                <Route
                  path="/securite"
                  element={<Securite />}
                />
                <Route
                  path="/faq"
                  element={<Faq />}
                />
                <Route
                  path="/Annuaire"
                  element={
                    <Navigate
                      to="/"
                      replace
                    />
                  }
                ></Route>
                <Route
                  path="/:idep"
                  element={healthcheck !== false ? <App /> : <Maintenance />}
                />
                <Route element={<div>Not found</div>} />
              </Routes>
            </main>
            <Footer />
          </BrowserRouter>
        </div>
      ) : (
        <Authentification />
      )}
    </ThemeProvider>
  )
}
