import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Paper } from '@mui/material'

export const styles = {
  paper: {
    width: '100%',
    height: '50px',
    position: 'absolute',
    bottom: 0
  },
  footer: {
    width: '80%',
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto'
  },
  title: {
    mt: '2%'
  },
  sectionTitle: {
    mt: '2%',
    mb: '1%'
  }
}

export default function Footer() {
  return (
    <Paper
      sx={styles.paper}
      elevation={12}
    >
      <footer
        style={styles.footer}
        role="contentinfo"
      >
        <Link
          to="accessibilite"
          style={{ color: 'black' }}
        >
          <Typography
            variant="body2"
            component="span"
          >
            Accessibilité
          </Typography>
        </Link>
        <Link
          to="mentionsLegales"
          style={{ color: 'black' }}
        >
          <Typography
            variant="body2"
            component="span"
          >
            Mentions légales
          </Typography>
        </Link>
        <Link
          to="securite"
          style={{ color: 'black' }}
        >
          <Typography
            variant="body2"
            component="span"
          >
            Sécurité
          </Typography>
        </Link>
        <Link
          to="faq"
          style={{ color: 'black' }}
        >
          <Typography
            variant="body2"
            component="span"
          >
            FAQ / Contact
          </Typography>
        </Link>
      </footer>
    </Paper>
  )
}
