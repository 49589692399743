import React from 'react'
import BlocPhoto from './BlocPhoto/BlocPhoto'
import BlocAgent from './BlocAgent/BlocAgent'
import BlocUnite from './BlocUnite/BlocUnite'
import { Grid } from '@mui/material'

export default function App() {
  document.title = "Annuaire de l'Insee"
  return (
    <Grid
      container
      style={{ width: '99.5%', marginTop: '1em', marginBottom: '0em' }}
      spacing={2}
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid
        item
        xs={12}
        sm={4}
      >
        <BlocUnite />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <BlocAgent />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <BlocPhoto />
      </Grid>
    </Grid>
  )
}
