import { compareUnitesByIdNoeud } from 'components/Utils/Fonctions'
import { GET_DIMENSION_SUCCESS, GET_DIMENSION_FAILURE, CLEAR_DIMENSION } from '../actions/dimensionAction'

const defaultStateDimension = {
  noeuds: [],
  erreur: ''
}

const sortUnite = (payload) => {
  return payload.sort(compareUnitesByIdNoeud)
}

export default function dimensionReducer(state = defaultStateDimension, action) {
  switch (action.type) {
    case GET_DIMENSION_SUCCESS:
      return { ...state, noeuds: sortUnite(action.payload.content), erreur: '' }
    case GET_DIMENSION_FAILURE:
      return { ...state, noeuds: [], erreur: action.error }
    case CLEAR_DIMENSION:
      return defaultStateDimension
    default:
      return state
  }
}
