/* eslint-disable import/no-cycle */
import { SET_TOKEN, SET_KEYCLOAK } from '../actions/tokenAction'

const defaultState = {
  kc: null,
  token: null,
  prenomNom: null,
  prenom: null,
  nom: null,
  idep: null,
  matricule: null
}

export default function tokenReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        prenomNom: action.payload.name,
        prenom: action.payload.given_name,
        nom: action.payload.family_name,
        idep: action.payload.preferred_username,
        matricule: action.payload.matricule
      }
    case SET_KEYCLOAK:
      return { ...state, kc: action.payload }
    default:
      return state
  }
}
