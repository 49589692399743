import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'

export default function Accessibilite() {
  document.title = "Accessibilité | Annuaire de l'Insee"
  return (
    <>
      <br />
      <Typography
        variant="h4"
        component="h2"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Accessibilité
      </Typography>
      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Naviguer au clavier
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- La navigation au clavier sur l'annuaire est permise par l'utilisation des touches directionnelles, des touches Tabulation, Shift, Entrée, et de la barre Espace." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Pour naviguer entre les différents éléments de l'annuaire, utilisez la touche Tabulation pour sélectionner l'élément suivant, ou Tab + Shift pour sélectionner l'élément précédent. Si une unité est sélectionnée et des agents affichés, cette manipulation permettra de passer d'un agent à un autre." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Si l'arbre des unités est sélectionné, les flèches directionnelles Haut et Bas vous permettent de naviguer de haut en bas dans l'arbre. La barre Espace permet de déplier (ou replier) le sous-arbre des sous-unités d'une unité s'il y en a et d'afficher les agents affectés sur cette unité. Pour sortir de l'arbre des unités, la touche Tabulation permet de passer à l'élément suivant." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Dans les champs de recherche, les échos s'actualisent automatiquement à chaque saisie d'un nouveau caractère. La navigation parmis ces réponses se fait par les flèches directionnelles Haut et Bas, la validation par la touche Entrée." />
        </ListItem>
      </List>
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Améliorations à venir
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Notre objectif est de rendre accessible cet outil à tous les agents de l'Insee, quel que soit leur situation,
        leur handicap, ou leur aptitude physique ou mentale. Pour cela, nous nous efforçons d’appliquer les critères
        définis dans le Référentiel Général d'Accessibilité pour les Administrations (RGAA).
      </Typography>
      <br />
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Nous travaillons actuellement sur les problèmes identifiés suivants :
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- En naviguant au clavier, accéder à la fiche d'un agent lorsqu'on le sélectionne dans la liste." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Vocaliser les résultats ou l'absence de résultats au cours d'une recherche." />
        </ListItem>
      </List>

      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Améliorations ajoutées
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Les problèmes suivants ont été traités :
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Problèmes de structuration du code html (titres, etc.)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Dans les résultats d'une recherche, l'élément survolé et l'élément sélectionné sont mieux indiqués par la couleur." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Après une certaine manipulation, l'arbre des unités n'était plus accessible par tabulation. Une solution a été trouvée par un nouveau composant." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- La navigation dans l'arbre des unités permet de vocaliser si il y a ou non des agents dans l'unité sélectionnée." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Il est possible d'effacer le texte saisi dans un champ de recherche en appuyant deux fois sur Echap." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Les avatars sont masqués pour les lecteurs d'écrans." />
        </ListItem>
      </List>
    </>
  )
}
