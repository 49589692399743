import { createTheme, responsiveFontSizes } from '@mui/material'
const insee = createTheme({
  palette: {
    type: 'light',
    primary: { main: '#F2F2F2' },
    secondary: { main: '#A92B40' },
    error: { main: '#e57373' },
    warning: { main: '#ffb74d' },
    info: { main: '#64b5f6' },
    success: { main: '#81c784' },
    canvas: { main: '#F7F7F7' },
    selected: { cadre: '#F5B8C2', texte: '#3F0714' },
    hover: { cadre: '#FFDAE0' },
    selectedHover: { cadre: '#DF8292' }
  }
})

insee.overrides = {
  MuiListItem: {
    root: {
      '&$selected': { backgroundColor: insee.palette.selected.cadre }
    }
  }
}

const dark = createTheme({
  palette: {
    type: 'dark',
    primary: { main: '#bf360c' },
    secondary: { main: '#9a0036' },
    error: { main: '#d32f2f' },
    warning: { main: '#f57c00' },
    info: { main: '#1976d2' },
    success: { main: '#388e3c' }
  }
})

export const InseeTheme = responsiveFontSizes(insee)

export const DarkTheme = responsiveFontSizes(dark)
