export const SET_NOEUD_SELECTED = 'SET_NOEUD_SELECTED'
export const CLEAR_NOEUD_SELECTED = 'CLEAR_NOEUD_SELECTED'

export function setNoeudSelected(params) {
  return (dispatch) => {
    dispatch({
      type: SET_NOEUD_SELECTED,
      payload: params
    })
  }
}
