import { SET_LOADING } from '../actions/loadingAction'

const defaultStateLoading = {
  loading: false
}

export default function loadingReducer(state = defaultStateLoading, action) {
  if (action.type === SET_LOADING) {
    return { ...state, loading: action.payload }
  }
  return state
}
