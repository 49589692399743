/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'
import dimensionReducer from './DimensionReducer'
import noeudOuvertReducer from './NoeudOuvertReducer'
import agentReducer from './AgentReducer'
import noeudSelectedReducer from './NoeudSelectedReducer'
import agentTotalReducer from './AgentTotalReducer'
import loadingReducer from './LoadingReducer'
import tokenReducer from './TokenReducer'
import healthcheckReducer from './HealthcheckReducer'

const rootReducer = combineReducers({
  dimensionReducer,
  noeudOuvertReducer,
  agentReducer,
  noeudSelectedReducer,
  agentTotalReducer,
  loadingReducer,
  tokenReducer,
  healthcheckReducer
})

export default rootReducer
