import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { clearAgent, getAgentByUnite, setAgentSelected } from 'actions/agentAction'
import store from 'index'
import { List, ListItemText, ListItemAvatar, Typography, ListItemButton } from '@mui/material'
import AvatarAgent from './AvatarAgent'
import { affichageNomPrenom } from '../Utils/Fonctions'
import { useNavigate } from 'react-router-dom'

const styles = {
  avatar: {
    color: 'primary.main',
    backgroundColor: 'secondary.main'
  },
  gras: { fontWeight: 'bold' },
  hover: {
    '&:focus, &:hover': {
      backgroundColor: 'hover.cadre'
    }
  },
  selectedHover: {
    backgroundColor: 'selected.cadre',
    '&:focus, &:hover': {
      backgroundColor: 'selectedHover.cadre'
    }
  }
}

export default function ListeAgent(callback, deps) {
  const agents = useSelector((store) => store.agentReducer.agents)
  const agentsTotal = useSelector((store) => store.agentTotalReducer.agentsTotal)
  const agentSelected = useSelector((store) => store.agentReducer.agentSelected)
  const noeudSelected = useSelector((store) => store.noeudSelectedReducer.noeudSelected)
  const noeuds = useSelector((store) => store.dimensionReducer.noeuds)
  let navigate = useNavigate()

  useEffect(() => {
    let newList = []
    const noeudComplet = noeuds.filter((n) => n.identifiantNoeudDimension === noeudSelected)
    if (noeudComplet?.[0]) {
      newList = noeudComplet[0].valeurs
      if (newList.length > 0) {
        store.dispatch(getAgentByUnite(newList, agentsTotal))
      } else {
        store.dispatch(clearAgent())
      }
    }
  }, [agentsTotal, noeudSelected, noeuds])

  const handleAgentClick = (value) => () => {
    store.dispatch(setAgentSelected(value))
    navigate(`/${value.identifiantsAgent.idep.toLowerCase()}`)
  }

  return (
    <div>
      {agents.length > 0 ? (
        <List dense>
          {agents.map((value) => {
            const labelId = `${value.identifiantsAgent.matricule}`
            const responsable = value.affectation.estResponsable ? ' (responsable)' : ''
            const select = agentSelected.identifiantsAgent
              ? agentSelected.identifiantsAgent.matricule === value.identifiantsAgent.matricule
              : false
            return (
              <ListItemButton
                key={labelId}
                onClick={handleAgentClick(value)}
                autoFocus={select}
                sx={select ? styles.selectedHover : styles.hover}
              >
                <ListItemAvatar>
                  <AvatarAgent
                    styles={styles.avatar}
                    agent={value}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography sx={select ? styles.gras : {}}>
                      {affichageNomPrenom(value.etatCivil.nomUsage, value.etatCivil.prenomUsuel) + responsable}
                    </Typography>
                  }
                />
              </ListItemButton>
            )
          })}
        </List>
      ) : (
        <Typography align="center">Aucun agent affecté sur l'unité sélectionnée</Typography>
      )}
    </div>
  )
}
