import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getInitiales, getIdNoeudsAscendants } from 'components/Utils/Fonctions'
import store from 'index'
import { clearAgent, setAgentSelected } from 'actions/agentAction'
import { setNoeudOuvert } from 'actions/noeudOuvertAction'
import { setNoeudSelected } from 'actions/noeudSelectedAction'
import { AppBar, Avatar, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material'

const styles = {
  avatar: {
    color: 'primary.main',
    backgroundColor: 'secondary.main'
  }
}

function getAgentByMatricule(agents, matricule) {
  return agents.filter((a) => a.identifiantsAgent.matricule === matricule)
}

export default function Header() {
  const tokenReducer = useSelector((store) => store.tokenReducer)
  const agentsTotal = useSelector((store) => store.agentTotalReducer.agentsTotal)
  const noeuds = useSelector((store) => store.dimensionReducer.noeuds)
  const initiales = tokenReducer.prenomNom ? getInitiales(tokenReducer.nom, tokenReducer.prenom) : ''
  const photo = useSelector((store) => store.agentReducer.photoConnectedAgent)
  const titre = 'Annuaire des personnes et services'

  const clickAvatar = (tokenReducer, agentsTotal) => () => {
    if (tokenReducer.matricule) {
      const agentMatricule = getAgentByMatricule(agentsTotal, tokenReducer.matricule)[0]
      if (agentMatricule) {
        // maj de l'agent sélectionné
        store.dispatch(setAgentSelected(agentMatricule))
        // maj du noeud sélectionné et des noeuds ouverts
        let idNoeudSelected = null
        let noeud = []
        let noeudsAscendants = []
        noeud = noeuds.filter((n) => n.valeurs.includes(agentMatricule.affectation.unite))
        if (noeud.length > 0) {
          idNoeudSelected = noeud[0].identifiantNoeudDimension
          noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud[0])
        }
        store.dispatch(setNoeudOuvert(noeudsAscendants))
        store.dispatch(setNoeudSelected(idNoeudSelected))
      }
    }
  }

  const clickLogoInsee = useCallback(() => {
    store.dispatch(clearAgent())
    store.dispatch(setNoeudOuvert([]))
    store.dispatch(setNoeudSelected(null))
  }, [])

  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Link
            to="/"
            aria-label="accueil"
            onClick={clickLogoInsee}
          >
            <IconButton
              edge="start"
              disabled
            >
              <img
                width="50"
                src={'./Logo_Insee.svg'}
                alt="Logo de l'Insee, cliquez dessus pour la page d'accueil"
              />
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            component="h1"
            sx={{ flexGrow: 1 }}
          >
            {titre}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <Typography
            variant="body1"
            component="span"
            style={{ marginRight: '15px', fontSize: '1.2rem' }}
          >
            {tokenReducer.prenomNom}
          </Typography>
          <Link
            to={`/${tokenReducer.idep.toLowerCase()}`}
            aria-label="votre profil"
            onClick={clickAvatar(tokenReducer, agentsTotal)}
          >
            <IconButton
              edge="start"
              disabled
            >
              <Avatar
                sx={styles.avatar}
                alt={tokenReducer.prenomNom}
                src={'data:image/jpeg;base64,' + photo}
                style={{ width: 60, height: 60 }}
              >
                {initiales}
              </Avatar>
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
    </>
  )
}
