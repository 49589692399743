/* eslint-disable import/no-cycle */
import API from '../api/api'

export const ERREUR_RESEAU = 'Une erreur est survenue'
export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS'
export const GET_AGENT_FAILURE = 'GET_AGENT_FAILURE'
export const CLEAR_AGENT = 'CLEAR_AGENT'
export const SET_AGENT_SELECTED = 'SET_AGENT_SELECTED'
export const GET_UNITE_AGENT_SELECTED_SUCCESS = 'GET_UNITE_AGENT_SELECTED_SUCCESS'
export const GET_UNITE_AGENT_SELECTED_FAILURE = 'GET_UNITE_AGENT_SELECTED_FAILURE'
export const GET_PHOTO_AGENT_SUCCESS = 'GET_PHOTO_AGENT_SUCCESS'
export const GET_PHOTO_AGENT_FAILURE = 'GET_PHOTO_AGENT_FAILURE'
export const GET_PHOTO_CONNECTED_AGENT_SUCCESS = 'GET_PHOTO_CONNECTED_AGENT_SUCCESS'
export const GET_PHOTO_CONNECTED_AGENT_FAILURE = 'GET_PHOTO_CONNECTED_AGENT_FAILURE'

global.Buffer = global.Buffer || require('buffer').Buffer

export function getAgentByUnite(unites, agents) {
  return (dispatch) => {
    dispatch({
      type: GET_AGENT_SUCCESS,
      payload: agents.filter((a) => unites.includes(a.affectation.unite))
    })
  }
}

export function getConnectedAgentPhoto(matricule) {
  let request = 'photo/' + matricule
  return (dispatch) => {
    API('')
      .then((api) =>
        api.get(request, {
          responseType: 'arraybuffer'
        })
      )
      .then((result) => {
        dispatch({
          type: GET_PHOTO_CONNECTED_AGENT_SUCCESS,
          payload: Buffer.from(result.data, 'binary').toString('base64')
        })
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_PHOTO_CONNECTED_AGENT_FAILURE,
            error: { message: ERREUR_RESEAU }
          })
        } else {
          dispatch({
            type: GET_PHOTO_CONNECTED_AGENT_FAILURE,
            error: error.response.data
          })
        }
      })
  }
}

export function getAgentPhoto(matricule) {
  let request = 'photo/' + matricule

  return (dispatch) => {
    API('')
      .then((api) =>
        api.get(request, {
          responseType: 'arraybuffer'
        })
      )
      .then((result) => {
        dispatch({
          type: GET_PHOTO_AGENT_SUCCESS,
          payload: { matricule: matricule, value: Buffer.from(result.data, 'binary').toString('base64') }
        })
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_PHOTO_AGENT_FAILURE,
            error: { message: ERREUR_RESEAU }
          })
        } else {
          dispatch({
            type: GET_PHOTO_AGENT_FAILURE,
            error: error.response.data
          })
        }
      })
  }
}

export function setAgentSelected(agent) {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_SELECTED,
      payload: agent
    })
  }
}

export function getUniteByAgent(codeHIE) {
  let requete = 'unite/' + codeHIE

  return (dispatch) => {
    API()
      .then((api) => api.get(requete))
      .then((result) => {
        dispatch({
          type: GET_UNITE_AGENT_SELECTED_SUCCESS,
          payload: result.data
        })
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_UNITE_AGENT_SELECTED_FAILURE,
            error: { message: ERREUR_RESEAU }
          })
        } else {
          dispatch({
            type: GET_UNITE_AGENT_SELECTED_FAILURE,
            error: error.response.data
          })
        }
      })
  }
}

export function clearAgent() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT
    })
  }
}
