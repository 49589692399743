import React from 'react'
import { Typography } from '@mui/material'
import pack from '../../../package.json'

export default function MentionsLegales() {
  document.title = "Mentions légales | Annuaire de l'Insee"
  return (
    <>
      <br />
      <Typography
        variant="h4"
        component="h2"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Mentions Legales
      </Typography>
      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Copyright
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Les données à caractère personnel présentées au travers de ce trombinoscope sont mises à disposition des agents
        de l'Insee dans le cadre de leur activité professionnelle.Elles n'ont pas vocation à être utilisées en dehors de
        ce contexte.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Les données sont issues de Sirhius et rafraîchies chaque matin.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Certaines informations, telles que les promotions de l'année, ne sont visibles qu'après leur saisie par les
        gestionnaires du CSRH.
      </Typography>
      <br />
      <Typography
        variant="h6"
        component="h3"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Informations éditeurs
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        <strong>Administration :</strong> Division Valorisarion des Données du Secrétariat Général
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        <strong>Maintenance informatique :</strong> Service national de développement informatique de Paris - Domaine
        Secrétariat Général
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        <strong>Version :</strong> {pack.version}
      </Typography>
    </>
  )
}
