import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InseeTheme } from '../Provider/material-ui-theme'
import { getUniteByAgent } from 'actions/agentAction'
import AvatarAgent from '../BlocAgent/AvatarAgent'
import { Link as LinkMUI, Typography, Card, CardHeader, CardContent } from '@mui/material'
import { getCivilite, getAdresseUnite } from 'components/Utils/Fonctions'

//css bloc photo
const styles = {
  root: {
    marginTop: '1em',
    padding: 0,
    maxHeight: '80vh',
    minHeight: '62vh',
    backgroundColor: 'canvas.main',
    overflow: 'auto',
    overflowY: 'scroll'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  avatar: {
    width: 100,
    height: 100,
    marginRight: 0,
    color: 'primary.main',
    backgroundColor: 'secondary.main',
    fontSize: '3em'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: InseeTheme.spacing(1),
    paddingBottom: InseeTheme.spacing(1)
  },
  text: {
    marginBottom: '0.5em'
  }
}

export default function BlocPhoto() {
  const dispatch = useDispatch()
  const agent = useSelector((store) => store.agentReducer.agentSelected)
  const uniteAgent = useSelector((store) => store.agentReducer.uniteAgentSelected)
  const [showBALF, setShowBALF] = useState(true)

  useEffect(() => {
    if (agent?.affectation) {
      dispatch(getUniteByAgent(agent.affectation.unite))
    }
  }, [agent, dispatch])

  const onBalfIconclick = () => {
    setShowBALF(!showBALF)
  }

  return agent?.etatCivil ? (
    <Card sx={styles.root}>
      <CardHeader
        avatar={
          <AvatarAgent
            styles={styles.avatar}
            agent={agent}
          />
        }
        title={
          <Typography
            variant="h6"
            component="h2"
            color="inherit"
          >
            {getCivilite(agent)}
          </Typography>
        }
        subheader={
          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Idep : </strong> {agent.identifiantsAgent.idep}
          </Typography>
        }
      />
      <CardContent
        sx={styles.content}
        style={{ paddingBottom: 0 }}
      >
        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Grade : </strong>
          {agent.carriere.grade.libelle}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          {agent.affectation.estResponsable === true ? (
            <strong>{"Est responsable de l'unité : "}</strong>
          ) : (
            <strong>{"Est agent de l'unité : "}</strong>
          )}
          {uniteAgent.libelleLong}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Timbre : </strong>
          {uniteAgent.timbre}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Fonction : </strong>
          {agent.affectation.fonction?.libelle}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Bureau : </strong>
          {agent.coordonnees?.bureau}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Téléphone : </strong>
          {agent.coordonnees?.telephoneProfessionnel}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Portable professionnel : </strong>
          {agent.coordonnees?.portableProfessionnel}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Mail : </strong>
          {agent.coordonnees?.email && (
            <LinkMUI href={'mailto:' + agent.coordonnees.email}>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                sx={{ ...styles.text, textDecoration: 'underline' }}
              >
                {agent.coordonnees.email}
              </Typography>
            </LinkMUI>
          )}
        </Typography>

        {agent?.balf?.length > 0 ? (
          <div>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={styles.text}
            >
              <strong>
                Boîtes fonctionnelles de l'agent :{' '}
                <button
                  onClick={onBalfIconclick}
                  style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
                >
                  {showBALF ? '▼' : '▶'}
                </button>
              </strong>
            </Typography>
            {showBALF && (
              <ul style={{ marginTop: -6, marginBottom: 3 }}>
                {agent.balf?.map((balf) => (
                  <li key={balf}>
                    <LinkMUI href={'mailto:' + balf.adresse}>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ ...styles.text, textDecoration: 'underline' }}
                      >
                        {balf.nom}
                      </Typography>
                    </LinkMUI>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <div />
        )}

        <Typography
          component="p"
          variant="subtitle2"
          color="textSecondary"
          sx={styles.text}
        >
          <strong>Adresse de l'unité : </strong>
          {getAdresseUnite(uniteAgent).map((value) => (
            <React.Fragment key={value}>
              <br />
              {value}
            </React.Fragment>
          ))}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <p></p>
  )
}
