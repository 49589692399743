export const champSansAccent = (champ) => {
  return champ
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const telephoneNettoye = (coordonnees) => {
  return coordonnees?.telephoneProfessionnel
    ? coordonnees.telephoneProfessionnel.trim().toLowerCase().replace(/\D+/g, '')
    : ''
}

export const portableProfessionnelNettoye = (coordonnees) => {
  return coordonnees?.portableProfessionnel
    ? coordonnees.portableProfessionnel.trim().toLowerCase().replace(/\D+/g, '')
    : ''
}

export const bureauNettoye = (coordonnees) => {
  return coordonnees?.bureau ? coordonnees.bureau.trim().toLowerCase() : ''
}

export const idepNettoye = (identifiantsAgent) => {
  return identifiantsAgent?.idep ? identifiantsAgent.idep.trim().toLowerCase() : ''
}

export const getInitiales = (nom, prenom) => {
  return (prenom.charAt(0) + nom.charAt(0)).toUpperCase()
}

export const affichageNomPrenom = (nom, prenom) => {
  return `${nom.toUpperCase()} ${prenom}`
}

export const getIdNoeudsAscendants = (noeuds, noeud) => {
  let noeudsAscendants = []
  if (noeud) {
    let noeudParent = null
    let idNoeudCourant = noeud.identifiantNoeudParent
    if (idNoeudCourant) {
      noeudsAscendants.push(idNoeudCourant)
    }
    do {
      noeudParent = noeuds.filter(
        // eslint-disable-next-line no-loop-func
        (n) => n.identifiantNoeudDimension === idNoeudCourant
      )
      idNoeudCourant = noeudParent?.[0]?.identifiantNoeudParent ?? null
      if (idNoeudCourant) {
        noeudsAscendants.push(idNoeudCourant)
      }
    } while (idNoeudCourant !== null)
  }
  return noeudsAscendants
}

export const getAgentByRecherche = (agents, recherche) => {
  // split de rechercher
  const rechercheSplit = recherche.split(' ').filter((r) => r !== '')
  let resultats = agents
  // bouche sur chaque element de recherche en repartant des éléments restants
  for (const element of rechercheSplit) {
    resultats = resultats.filter((a) => {
      const balf = a.balf
      const tel = telephoneNettoye(a.coordonnees)
      const portablePro = portableProfessionnelNettoye(a.coordonnees)
      const bureau = bureauNettoye(a.coordonnees)
      const idep = idepNettoye(a.identifiantsAgent)

      return (
        champSansAccent(affichageNomPrenom(a.etatCivil.nomUsage, a.etatCivil.prenomUsuel)).includes(element) ||
        tel.includes(element) ||
        portablePro.includes(element) ||
        bureau.includes(element) ||
        idep.includes(element) ||
        balf?.some(
          (item) => champSansAccent(item.nom).includes(element) || champSansAccent(item.adresse).includes(element)
        )
      )
    })
  }
  return resultats
}

export const compareAgentsByName = (a, b) => {
  return affichageNomPrenom(a.etatCivil.nomUsage, a.etatCivil.prenomUsuel).localeCompare(
    affichageNomPrenom(b.etatCivil.nomUsage, b.etatCivil.prenomUsuel)
  )
}

export const compareUnitesByLibelle = (a, b) => {
  return a.libelle.localeCompare(b.libelle)
}

export const compareUnitesByIdNoeud = (a, b) => {
  return a.identifiantNoeudDimension.localeCompare(b.identifiantNoeudDimension)
}

export const getCivilite = (agent) => {
  let civilite = ''
  if (agent.etatCivil.civilite?.code) {
    civilite = agent.etatCivil.civilite.code === '1' ? 'M ' : 'Mme '
  }
  civilite += agent.etatCivil.nomUsage + ' ' + agent.etatCivil.prenomUsuel
  return civilite
}

export const getAdresseUnite = (uniteAgent) => {
  let adresse = ''

  if (uniteAgent.adresseFrance) {
    adresse = getAdresseFrance(uniteAgent)
  } else if (uniteAgent.adresseEtranger) {
    adresse = getAdresseEtranger(uniteAgent)
  }
  return adresse.split('\n')
}

function getAdresseFrance(uniteAgent) {
  const adresseFrance = {
    numeroVoie: ' ',
    extension: ' ',
    nomVoie: '\n',
    complementAdresse: '\n',
    codePostal: ' ',
    bureauDistribution: ''
  }
  let adresse = ''
  for (const key in uniteAgent.adresseFrance) {
    if (!uniteAgent.adresseFrance['bureauDistribution'] && key === 'commune' && uniteAgent.adresseFrance.commune) {
      adresseFrance['commune'] = uniteAgent.adresseFrance[key] + ' '
    } else if (Object.keys(adresseFrance).includes(key) && uniteAgent.adresseFrance[key]) {
      adresseFrance[key] = uniteAgent.adresseFrance[key] + adresseFrance[key]
    }
  }
  for (const key in adresseFrance) {
    if (adresseFrance[key] !== ' ' && adresseFrance[key] !== '\n') {
      adresse += adresseFrance[key]
    }
  }
  return adresse
}

function getAdresseEtranger(uniteAgent) {
  const adresseEtranger = {
    numeroVoie: !uniteAgent.adresseEtranger.complement1 ? '\n' : ' ',
    complement1: '\n',
    complement2: '\n',
    codePostal: ' ',
    ville: uniteAgent.adresseEtranger.ville ? ' - ' : '',
    paysLibelle: ''
  }
  let adresse = ''
  for (const key in uniteAgent.adresseEtranger) {
    if (Object.keys(adresseEtranger).includes(key) && uniteAgent.adresseEtranger[key]) {
      adresseEtranger[key] = uniteAgent.adresseEtranger[key] + adresseEtranger[key]
    }
  }
  for (const key in adresseEtranger) {
    if (adresseEtranger[key] !== ' ' && adresseEtranger[key] !== '\n') {
      adresse += adresseEtranger[key]
    }
  }
  return adresse
}
